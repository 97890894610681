import React from 'react'
import Person from 'eevee-components/person'
import connect from '@h/connect-redux'
import { getModuleFromIncludes, includes } from '@r/contentful-selectors'
import { contentId } from '@u/contentful'
import { ModulesToReactComponents } from '@h/modules'

const WrappedPerson = props => {
  const url = props.image?.file.url || ''
  const bio = ModulesToReactComponents(props.bio?.content)
  return <Person {...props} bio={bio} image={url}></Person>
}

export default connect((state, props) => {
  const image = getModuleFromIncludes(includes(state), contentId(props.image)).fields
  return { image }
})(WrappedPerson)
